import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './index.scss';

const CalendarApps = () => {
  return (
    <div className="calendar">
      <div className="card">
        <div className="card-header d-flex jc-between">Программы для отчетов</div>

        <div className="card-content">
          <div className="scroll-x">
            <table className="big-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Название</th>
                  <th style={{ width: '30%' }}>Описание</th>
                  <th>Сборка программы</th>
                  <th>Дата выхода</th>
                  <th>Дата обновления</th>
                  <th>Ссылка на скачивание</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.20.8</td>
                  <td>02.09.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="http://download.cbr.ru/ANKETA_MFO_20240902_2.16.4_1.3.20.8-202409191500.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.20.7</td>
                  <td>06.06.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2024/MFO_20240606_2.16.4_1.3.20.7.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>Программа-анкета дохода об операциях с денежными средствами</td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 10 января 2022 г. № 6054-У «О формах,
                    сроках и порядке составления и предоставления в Банк России отчета об операциях с денежными
                    средствами отдельных некредитных финансовых организаций».
                  </td>
                  <td>0.0.0.40</td>
                  <td>03.04.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2024/ODS_20240403_2.16.4_0.0.0.40.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 614-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 614-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.45</td>
                  <td>21.02.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2024/MFO614P_20240221_2.16.4_0.0.0.45.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.20.1</td>
                  <td>12.02.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2024/MFO_20240212_2.16.4_1.3.20.1.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.19.9</td>
                  <td>25.01.2024</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2024/MFO_20240125_2.16.4_1.3.19.9.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.19.8</td>
                  <td>21.12.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO_20231221_2.16.4_1.3.19.8.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 613-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 613-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.60</td>
                  <td>05.09.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO613P_20230905_2.16.4_0.0.0.60.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.19.3</td>
                  <td>26.06.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO_20230626_2.16.4_1.3.19.3.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.18.8</td>
                  <td>05.06.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023//MFO_20230605_2.16.4_1.3.18.8.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 614-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 614-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.45</td>
                  <td>12.04.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO614P_20230412_2.16.4_0.0.0.45.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 16.11.2022 № 6316-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.18.7</td>
                  <td>05.04.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO_20230405_2.16.4_1.3.18.7.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.18.0</td>
                  <td>11.01.2023</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2023/MFO_20230111_2.16.4_1.3.18.0.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.17.9</td>
                  <td>16.12.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO_20221216_2.16.4_1.3.17.9.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщения о годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.17.4</td>
                  <td>04.10.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO_20221004_2.16.4_1.3.17.4.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>Программа-анкета дохода об операциях с денежными средствами</td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 10 января 2022 г. № 6054-У «О формах,
                    сроках и порядке составления и предоставления в Банк России отчета об операциях с денежными
                    средствами отдельных некредитных финансовых организаций».
                  </td>
                  <td>0.0.0.40</td>
                  <td>12.09.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/ODS_20220912_2.16.4_0.0.0.40.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщенияо годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.17.1</td>
                  <td>23.05.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO_20220523_2.16.4_1.3.17.1.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 613-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 613-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.60</td>
                  <td>18.05.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO613P_20220518_2.16.4_0.0.0.60.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 614-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 614-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.45</td>
                  <td>15.04.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO614P_20220415_2.16.4_0.0.0.45.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представлениях в Банке России микрофинансовых компаний и микрокредитных
                    компаний, порядке и сроках представления микрофинансовых сумм в Банке России аудиторского пропуска о
                    годовой бухгалтерской отчетности» (финансовой) отчетности, вероятности и периода выявления
                    микрофинансовых доходов бухгалтерской (финансовой) отчетности и аудиторского сообщенияо годовой
                    бухгалтерской (финансовой) отчетности».
                  </td>
                  <td>1.3.16.8</td>
                  <td>30.03.2022</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2022/MFO_20220330_2.16.4_1.3.16.8.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представления в Банк России отчетности микрофинансовых компаний и
                    микрокредитных компаний, порядке и сроках представления микрофинансовыми компаниями в Банк России
                    аудиторского заключения о годовой бухгалтерской (финансовой) отчетности, порядке и сроках раскрытия
                    микрофинансовыми компаниями бухгалтерской (финансовой) отчетности и аудиторского заключения о
                    годовой бухгалтерской (финансовой) отчетности». Добавлена форма «Сопроводительное письмо СРО МКК»
                    для возможности представления саморегулируемыми организациями отчетности своих членов -
                    микрокредитных компаний. Отчетность составляется и представляется в Банк России в соответствии с
                    требованиями Указания Банка России № 5523-У начиная с отчетности: для микрофинансовых компаний – за
                    апрель 2021 года; для микрокредитных компаний – за первое полугодие 2021 года.
                  </td>
                  <td>1.3.16.0</td>
                  <td>21.12.2021</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2021/MFO_20211221_2.16.3_1.3.16.0.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки и направления электронных документов в личный кабинет участника
                    информационного обмена
                  </td>
                  <td>
                    Данная версия Программы-анкеты предназначена для корректной реализации процедуры подготовки и
                    направления электронных документов в Банк России
                  </td>
                  <td>1.0.0.13</td>
                  <td>17.12.2021</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2021/UIO_20211217_2.16.3_1.0.0.13.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности НФО в
                    соответствии с Положением 613-П
                  </td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 613-П для некредитных финансовых
                    организаций(МКК и Фондов)
                  </td>
                  <td>0.0.0.60</td>
                  <td>08.12.2021</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2021/MFO613P_20211208_2.16.4_0.0.0.60.zip">Скачать</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    Формы отчетности в соответствии с Указанием Банка России от 03.08.2020 № 5523-У «О формах, сроках и
                    порядке составления и представления в Банк России отчетности микрофинансовых компаний и
                    микрокредитных компаний, порядке и сроках представления микрофинансовыми компаниями в Банк России
                    аудиторского заключения о годовой бухгалтерской (финансовой) отчетности, порядке и сроках раскрытия
                    микрофинансовыми компаниями бухгалтерской (финансовой) отчетности и аудиторского заключения о
                    годовой бухгалтерской (финансовой) отчетности». Добавлена форма «Сопроводительное письмо СРО МКК»
                    для возможности представления саморегулируемыми организациями отчетности своих членов -
                    микрокредитных компаний. Отчетность составляется и представляется в Банк России в соответствии с
                    требованиями Указания Банка России № 5523-У начиная с отчетности: для микрофинансовых компаний – за
                    апрель 2021 года; для микрокредитных компаний – за первое полугодие 2021 года.
                  </td>
                  <td>1.3.14.3</td>
                  <td>29.03.2021</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2021/MFO_20210329_2.16.3_1.3.14.3.zip">
                      Скачать, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Программа-анкета подготовки электронных документов для МФК и МКК</td>
                  <td>предыдущая версия анкета-редактор 1.3.05.6</td>
                  <td>1.2.99.6</td>
                  <td>02.03.2018</td>
                  <td> ---</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/reg-mfo.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Возможность сохранений и загрузки определенных разделов (подразделов) отчета в программе
                    «Анкета-Редактор»
                  </td>
                  <td>
                    Руководство пользователя по переносу информации из старого отчета в новую версию Программы-анкета с
                    помощью функции копирования.Сохранение и загрузка разделов (подразделов) в Программе-анкете.
                  </td>
                  <td> ---</td>
                  <td> ---</td>
                  <td> ---</td>
                  <td>
                    <a href="https://cbr.ru/Content/Document/File/65080/guide_anketa_2.docx">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний - Добавлена форма: «Уведомление о получении права распоряжения 10 и более процентами
                    голосов» - Программа-анкета подготовки электронных документов для микрофинансовых организаций
                  </td>
                  <td>
                    <table className="min-0">
                      <tbody>
                        <tr>
                          <td>
                            {' '}
                            «Отчет о микрофинансовой деятельности микрофинансовой компании (ОКУД 0420840)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о микрофинансовой деятельности микрокредитной компании (ОКУД 0420846)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о средневзвешенных значениях полной стоимости потребительских микрозаймов(ОКУД
                            0420847)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о персональном составе микрофинансовой организации (ОКУД 0420841)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Уведомление о получении права распоряжения 10 и более процентами голосов,приходящиеся на
                            голосующие акции(доли)составляющие Уставный капитал микрокредитной компании (Указание Банка
                            России от 21.12.2017г №4658-У)
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Заявление о внесении изменений в сведения о микрофинансовой организации, содержащиеся в
                            государственном реестре микрофинансовых организаций».
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>1.3.12.3</td>
                  <td>27.03.2020</td>
                  <td>22.04.2020</td>
                  <td>
                    <a
                      style={{ color: 'red' }}
                      href="https://cbr.ru/static/fcsm/files/2020/MFO_20200327_2.16.3_1.3.12.3.zip"
                    >
                      Скачать, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Программа-анкета подготовки электронных документов для некредитных финансовых организаций</td>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 613-П{' '}
                    <b>для некредитных финансовых организаций(МКК и Фондов)</b>
                  </td>
                  <td>0.0.0.60</td>
                  <td>28.04.2020</td>
                  <td>30.04.2020</td>
                  <td>
                    <a href="https://cbr.ru/static/fcsm/files/2020/MFO613P_20200428_2.16.3_0.0.0.60.zip">
                      Скачать, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования бухгалтерской отчетности МФО
                  </td>
                  <td>
                    Программа-анкета, предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности в форме электронного документа с усиленной квалифицированной электронной
                    подписью в соответствии с Положением Банка России от 25.10.2017 № 614-П{' '}
                    <b>для микрофинансовых компаний(МФК)</b>
                  </td>
                  <td>0.0.0.45</td>
                  <td>29.04.2020</td>
                  <td>30.04.2020</td>
                  <td>
                    <a href="https://cbr.ru/static/fcsm/files/2020/MFO614P_20200429_2.16.3_0.0.0.45.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов, содержащая Отчетность об операциях с денежными
                    средствами
                  </td>
                  <td>
                    Данная версия Программы-анкеты предназначена исключительно для подготовки Отчетности об операциях с
                    денежными средствами и может использоваться одновременно с иными версиями Программы-анкеты
                    подготовки электронных документов
                  </td>
                  <td>0.0.0.40</td>
                  <td>09.12.2019</td>
                  <td>13.12.2019</td>
                  <td>
                    <a href="https://cbr.ru/static/fcsm/files/2019/ODS_20191209_2.16.3_0.0.0.40.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования подписанного усиленной
                    квалифицированной электронной подписью уведомления о принятии электронного сообщения либо
                    уведомления о непринятии электронного сообщения, предусмотренного Положением Банка России № 639-П
                  </td>
                  <td>
                    Положение о порядке, сроках и объеме доведения до сведения кредитных организаций и некредитных
                    финансовых организаций информации о случаях отказа от проведения операции, отказа от заключения
                    договора банковского счета (вклада) и (или) расторжения договора банковского счета (вклада) с
                    клиентом, об устранении оснований принятия решения об отказе от проведения операции, об устранении
                    оснований принятия решения об отказе от заключения договора банковского счета (вклада), об
                    отсутствии оснований для расторжения договора банковского счета (вклада) с клиентом»,
                    предусмотренного <b>Положением Банка России от 30.03.2018 № 639-П</b>
                  </td>
                  <td>0.0.0.100</td>
                  <td>05.04.2019</td>
                  <td>08.04.2019</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2019/639p_20190405_2.16.3_0.0.0.100.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для формирования отчетов о движении средств по
                    счетам (вкладам) в банках за пределами территории Российской Федерации
                  </td>
                  <td>В соответствии с Указанием Банка России от 27.12.2018 № 5047-У, версия 2.16.3</td>
                  <td>1.3.13.3</td>
                  <td>13.08.2020</td>
                  <td>09.09.2020</td>
                  <td>
                    <a href="https://www.cbr.ru/static/fcsm/files/2020/ODSF_20200813_2.16.3_1.3.13.3.zip">Скачать</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета подготовки электронных документов для микрофинансовых компаний и микрокредитных
                    компаний
                  </td>
                  <td>
                    <table className="min-0">
                      <tbody>
                        <tr>
                          <td>
                            {' '}
                            «Отчет о микрофинансовой деятельности микрофинансовой компании (ОКУД 0420840)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о микрофинансовой деятельности микрокредитной компании (ОКУД 0420846)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о средневзвешенных значениях полной стоимости потребительских микрозаймов(ОКУД
                            0420847)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            «Отчет о персональном составе микрофинансовой компании (ОКУД 0420841)»
                            <span style={{ color: 'red' }}>
                              (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от
                              другой даты)
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>1.3.05.6</td>
                  <td>19.02.2019</td>
                  <td>01.03.2019</td>
                  <td>
                    <a style={{ color: 'red' }} href="#">
                      Удалить, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Программа-анкета подготовки электронных документов для микрофинансовых организаций</td>
                  <td>
                    «Заявление о внесении изменений в сведения о микрофинансовой организации, содержащиеся в
                    государственном реестре микрофинансовых организаций».
                    <span style={{ color: 'red' }}>
                      (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от другой
                      даты)
                    </span>
                  </td>
                  <td>1.3.08.4</td>
                  <td>05.07.2019</td>
                  <td>09.07.2019</td>
                  <td>
                    <a style={{ color: 'red' }} href="#">
                      Удалить, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Программа-анкета подготовки электронных документов для МФК и МКК</td>
                  <td>
                    Предыдущая версия анкета-редактор 1.3.12.3{' '}
                    <span style={{ color: 'red' }}>
                      {' '}
                      (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от другой
                      даты)
                    </span>
                  </td>
                  <td>1.3.09.9</td>
                  <td>19.09.2019</td>
                  <td>---</td>
                  <td>
                    <a
                      style={{ color: 'red' }}
                      href="https://cbr.ru/static/fcsm/files/2019/MFO_20190919_2.16.3_1.3.09.9.zip"
                    >
                      Скачать, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности <strong>для (МКК и Фондов)</strong>
                  </td>
                  <td>
                    Предыдущая версия анкета-редактор{' '}
                    <span style={{ color: 'red' }}>
                      (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от другой
                      даты)
                    </span>
                  </td>
                  <td>0.0.0.60</td>
                  <td>05.02.2019</td>
                  <td>---</td>
                  <td>
                    <a
                      style={{ color: 'red' }}
                      href="https://cbr.ru/static/fcsm/files/2019/MFO613P_20190502_2.16.3_0.0.0.60.zip"
                    >
                      Скачать, устарела
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>
                    Программа-анкета предназначенная для формирования и представления в Банк России бухгалтерской
                    (финансовой) отчетности <strong>для (МКК и Фондов)</strong>
                  </td>
                  <td>
                    Предыдущая версия анкета-редактор{' '}
                    <span style={{ color: 'red' }}>
                      (не может быть установлена и использоваться одновременно с версиями Программы-анкеты от другой
                      даты)
                    </span>
                  </td>
                  <td>0.0.0.60</td>
                  <td>10.12.2019</td>
                  <td>---</td>
                  <td>
                    <a
                      style={{ color: 'red' }}
                      href="https://cbr.ru/static/fcsm/files/2019/MFO613P_20191210_2.16.3_0.0.0.60.zip"
                    >
                      Скачать, устарела
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarApps;
